import React, { useState, useEffect } from "react";
import { useLanguage } from "../context/LanguageContext";

function VerticalTimeline() {
  const [timeline, setTimeline] = useState([]);
  const [loading, setLoading] = useState(true);
  const { language } = useLanguage() || { language: "en" }; // Fallback to "en" if language is undefined

  useEffect(() => {
    const loadTimelineData = async () => {
      setLoading(true);
      try {
        let data;
        if (language === "es") {
          data = await import("../data/timeline_es.json");
        } else {
          data = await import("../data/timeline.json");
        }
        setTimeline(data.default);
      } catch (error) {
        console.error("Error loading timeline data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadTimelineData();
  }, [language]);

  if (loading) {
    return <div className="text-center py-8">Loading timeline...</div>;
  }

  return (
    <div className="relative m-8">
      <div className="border-r-2 border-gray-500 absolute h-full top-0 left-4"></div>
      <ul className="list-none m-0 p-0">
        {timeline.map((current, index) => (
          <li
            className="mb-10 flex flex-col md:flex-row items-center"
            key={index}
          >
            <div className="flex items-center justify-center bg-gray-500 rounded-full h-12 w-12 md:h-8 md:w-8 text-2xl md:text-xl text-center mb-4 md:mb-0">
              <p className={`fa ${current.icon} text-white`}></p>
            </div>
            <div className="ml-0 md:ml-4 text-center md:text-left">
              <div className="font-bold text-lg md:text-base">
                {current.title}
              </div>
              <div className="mt-1 text-xs font-mono text-gray-600">
                {current.date}
              </div>
              <div className="ml-12 md:ml-4 mt-2">{current.description}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default VerticalTimeline;
