import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import VerticalTimeline from "../components/verticalTimeline";
import { useLanguage } from "../context/LanguageContext";
import { translate } from "../utils/translation";

function AboutPage() {
  const { language } = useLanguage() || { language: "en" }; // Fallback to "en" if language is undefined

  return (
    <Layout>
      <SEO
        keywords={[
          `web developer`,
          `Giuseppe Gangi`,
          `Barcelona`,
          `full stack developer`,
        ]}
        title={translate("about.title", language)}
      />

      <section className="container mx-auto px-4 py-4">
        <div className="text-center mb-4">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">
            {translate("about.title", language)}
          </h1>
        </div>

        <div className="flex flex-col md:flex-row items-center md:space-x-8">
          <div className="w-full md:w-1/3 mb-8 md:mb-0">
            <img
              src="https://github.com/ggangix.png"
              className="w-48 h-48 rounded-full mx-auto object-cover shadow-lg border-4 border-gray-200"
              alt="Giuseppe Gangi"
            />
          </div>
          <div className="w-full md:w-2/3">
            <p className="text-lg text-gray-700 leading-relaxed mb-4 text-justify">
              {translate("about.description1", language)}
            </p>
            <p className="text-lg text-gray-700 leading-relaxed mb-4 text-justify">
              {translate("about.description2", language)}
            </p>
            <p className="text-lg text-gray-700 leading-relaxed mb-4 text-justify">
              {translate("about.description3", language)}
            </p>
            <p className="text-lg text-gray-700 leading-relaxed mb-6 text-justify">
              {translate("about.description4", language)}
            </p>
            <p className="text-lg text-gray-700 text-justify">
              {translate("about.contact", language)}{" "}
              <a
                className="font-bold text-blue-600 underline hover:no-underline"
                href="/contact"
              >
                {translate("about.contact_link", language)}
              </a>
              .
            </p>
          </div>
        </div>
      </section>

      <section className="bg-gray-50 py-12">
        <div className="container mx-auto px-4">
          <VerticalTimeline />
        </div>
      </section>
    </Layout>
  );
}

export default AboutPage;
